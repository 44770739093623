(function(module) {
try {
  module = angular.module('templates-app');
} catch (e) {
  module = angular.module('templates-app', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/validador/validador.add.tpl.html',
    '<style>\n' +
    '.headeroj {\n' +
    '    background: #093958;\n' +
    '    background: url(\'img/cintillo.png\');\n' +
    '    border-bottom: 1px solid #09355a;\n' +
    '    height:130px;\n' +
    '    margin: 0 auto;\n' +
    '    background-size: 100% auto;\n' +
    '    background-repeat: no-repeat;\n' +
    '}\n' +
    'h1.logooj {\n' +
    '	  position:absolute;\n' +
    '    background: no-repeat left;\n' +
    '    display: block;\n' +
    '    width: 90%;\n' +
    '    height: 107px;\n' +
    '	  margin-left:30px;\n' +
    '	  margin-top:10px;\n' +
    '    background-image: url(\'img/logo_principal.png\');\n' +
    '}\n' +
    '#textooj {\n' +
    '    top: 35px;\n' +
    '    position: absolute;\n' +
    '    right: 10px;\n' +
    '	color:#FFF;\n' +
    '}\n' +
    '.bodyoj {\n' +
    '    background: url(\'img/IMG_7025.jpg\');\n' +
    '    width: auto;\n' +
    '	  height:auto;\n' +
    '    margin: 0 auto;\n' +
    '    background-size: 100% auto;\n' +
    '    background-repeat: no-repeat;\n' +
    '}\n' +
    '.footeroj {\n' +
    '   bottom:0;\n' +
    '   width: 1024px;\n' +
    '   height:25px;\n' +
    '   background:#039;\n' +
    '   margin: 0 auto;\n' +
    '   text-align:center;\n' +
    '   color:#FFF;\n' +
    '}\n' +
    '.box-body {\n' +
    '  height: auto;\n' +
    '  min-height:500px;\n' +
    '}\n' +
    '</style><div class="box box-primary"><div class="box-header with-border headeroj"><h1 class="logooj"><div id="textooj">Toma de posesión 2016-2017</div></h1></div><form name="form" ng-submit="submitForm(form.$valid)" novalidate=""><div class="box-body bodyoj"><div class="row"><div class="col-sm-4"></div><div class="col-sm-4 text-center" ng-class="{ \'has-error\' : form.general.$invalid && form.$submitted }"><label class="control-label" for="general">Ingrese el codigo</label><div class="fg-line form-group"><input class="form-control" id="general" name="general" ng-model="data.general" focus-on="general" required=""></div></div><div class="col-sm-4"></div></div><div class="row"><div class="col-sm-4 col-md-offset-4" ng-show="valido == 0"><div class="small-box bg-red"><div class="inner" align="center"><h3>{{codigo}}</h3><p>No válido</p></div><a href="#" class="small-box-footer">{{mensaje}} <i class="fa fa-arrow-circle-right"></i></a></div></div></div><div class="row"><div class="col-sm-4 col-md-offset-4" ng-show="valido == 1"><div class="small-box bg-green"><div class="inner" align="center"><h3>{{codigo}}</h3><p>Válido</p></div><a href="#" class="small-box-footer">{{mensaje}} <i class="fa fa-arrow-circle-right"></i></a></div></div></div><div class="row"><div class="col-sm-12 text-center"><button type="submit" class="btn btn-success btn-sm">Validar</button></div></div></div></form></div>');
}]);
})();
