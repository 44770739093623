(function(module) {
try {
  module = angular.module('templates-app');
} catch (e) {
  module = angular.module('templates-app', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/home/home.tpl.html',
    '<div class="box box-primary"><div class="box-body text-center"><img src="img/cintillo2.png" style="width:60%"></div><div class="box-footer text-left"><label class="control-label"><small></small></label></div></div>');
}]);
})();
