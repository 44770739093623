(function(module) {
try {
  module = angular.module('templates-app');
} catch (e) {
  module = angular.module('templates-app', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/agenda/agenda.comentario.tpl.html',
    '<div class="panel"><div class="box box-primary"><div class="box-header with-border"><div class="row"><div class="col-sm-2"><h1 class="box-title"><b>Agregar comentario</b></h1></div><div class="col-sm-4"><h4 class="box-title"><b>Agenda {{sesion.correlativo}}-{{sesion.anio}}</b></h4></div><div class="col-sm-3 text-right"><h5 ng-show="mostrarCompartido > 0"><b><input type="checkbox" ng-model="sesion.bndEstado"> Compartir con secretario</b></h5></div><div class="col-sm-3 text-right"><button type="button" class="btn btn-success btn-sm" ng-click="actualizarComentario()" ng-show="!existComment">Guardar</button> <button type="button" class="btn btn-success btn-sm" ng-click="guardarComentario()" ng-show="existComment">Guardar</button> <button type="button" class="btn btn-info btn-sm" ng-click="limpiarComentario()" ng-show="!existComment">Limpiar</button> <button type="button" class="btn btn-primary btn-sm" ng-click="cerrarComentario()">Regresar</button></div></div></div><div class="box-body"><div class="panel panel-success"><div class="panel-heading"><h5><b>{{detalle.descripcion}}</b></h5></div><div class="panel-body"><div class="row"><div class="col-sm-12 col-md-12 col-lg-12"><textarea class="form-control font-size-normal" rows="10" name="comentario" ng-model="sesion.comentario">\n' +
    '            </textarea></div></div></div></div></div></div></div>');
}]);
})();
