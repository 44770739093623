(function(module) {
try {
  module = angular.module('templates-app');
} catch (e) {
  module = angular.module('templates-app', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/agenda/agendahelp.tpl.html',
    '<div class="panel panel-success"><div class="panel-heading"><h4><b>Información de ayuda</b></h4></div><div class="panel-body"><div class="row"><div class="ui-grid-cell-contents text-center col-options"><span><button type="button" class="btn btn-default btn-xs dropdown-toggle text-yellow" title="Archivos Adjuntos"><i class="zmdi zmdi-attachment-alt text-black icon-big"></i><span class="label label-danger span">1</span></button></span></div><div class="col-sm-6 col-md-6 col-lg-6"><div class="ui-grid-cell-contents text-center col-options"><span><button type="button" class="btn btn-default btn-xs dropdown-toggle text-yellow" title="Archivos Adjuntos"><i class="zmdi zmdi-attachment-alt text-black icon-big"></i><span class="label label-danger span">1</span></button></span></div></div><div class="col-sm-6 col-md-6 col-lg-6">Archivos adjuntos</div></div></div><div class="box-footer text-right"><button type="button" class="btn btn-primary btn-sm" ng-click="cerrarAyuda">Regresar</button></div></div>');
}]);
})();
