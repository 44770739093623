(function(module) {
try {
  module = angular.module('templates-app');
} catch (e) {
  module = angular.module('templates-app', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/validador/validador.list.tpl.html',
    '<div ui-grid="gridOptionsusuario" ui-grid-pagination="" ui-grid-selection="" class="grid"></div><div class="box-footer text-right"><button type="button" class="btn btn-primary btn-sm" ui-sref="^.add">Agregar</button></div>');
}]);
})();
