(function(module) {
try {
  module = angular.module('templates-app');
} catch (e) {
  module = angular.module('templates-app', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/agenda/agenda.adddetalle.tpl.html',
    '<div class="box box-primary"><div class="box-header with-border"><h3 class="box-title">Agregar sesión de Pleno</h3></div><form name="form" ng-submit="submitForm(form.$valid)" novalidate=""><div class="box-body"><div class="row"><div class="col-sm-9"><button type="button" class="btn btn-success btn-sm" ng-click="addDetalle()">Agregar</button></div></div><div class="row"><div class="col-sm-12 col-md-12 col-lg-12"><div ui-grid="gridOptionsDetalle" ui-grid-edit="" class="grid" ui-grid-tree-view="" ui-grid-auto-resize=""></div></div></div></div><div class="box-footer text-right"><button type="submit" class="btn btn-success btn-sm">Guardar</button> <button type="button" class="btn btn-danger btn-sm" ui-sref="^.listadmin">Regresar</button></div></form></div>');
}]);
})();
