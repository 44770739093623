(function(module) {
try {
  module = angular.module('templates-app');
} catch (e) {
  module = angular.module('templates-app', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/agenda/agenda.view.tpl.html',
    '<style>\n' +
    '  *{box-sizing: border-box;}\n' +
    '  .wrapper {margin: 0 auto; width: auto; }\n' +
    '  .pdf-controls { width: 100%; display: block; background: #eee; padding: 1em;}\n' +
    '  .rotate0 {-webkit-transform: rotate(0deg); transform: rotate(0deg); }\n' +
    '  .rotate90 {-webkit-transform: rotate(90deg); transform: rotate(90deg); }\n' +
    '  .rotate180 {-webkit-transform: rotate(180deg); transform: rotate(180deg); }\n' +
    '  .rotate270 {-webkit-transform: rotate(270deg); transform: rotate(270deg); }\n' +
    '  .fixed { position: fixed; top: 0; left: calc(50% - 480px); z-index: 100; width: 100%; padding: 1em; background: rgba(238, 238, 238,.9); width: 960px; }\n' +
    '</style><div class="box box-primary"><div class="box-header with-border text-right"></div><form name="form" ng-submit="submitForm(form.$valid)" novalidate=""><div class="box-body"><div class="row"><div class="col-sm-11 text-center"><h2><span class="label label-primary">Agenda de Sesión {{sesion.tipoSesion==1?\'Ordinaria\':\'Extraordinaria\'}} {{sesion.correlativo}} - {{sesion.anio}}</span></h2></div><div class="col-sm-1 text-right"><button type="button" class="btn btn-default btn-sm text-blue icon-big text-right" ng-click="mostrarAyuda()" title="Mostrar ayuda"><i class="zmdi zmdi-help"></i></button></div></div><br><div class="row"><div class="col-sm-12 col-md-12 col-lg-12"><h1><span class="text-black">Fecha y hora:</span> <span class="label label-info">{{sesion.fechaHora | date: dateOptions.formatDateTime}}</span></h1></div></div><br><div class="row"><div class="col-sm-6" ng-show="collapse"><label class="control-label" for="descripcion">Descripción</label> <textarea class="form-control" rows="5" name="descripcion" ng-model="sesion.descripcion" readonly="">\n' +
    '        </textarea></div></div><div></div><div class="row"><div class="col-sm-3"><button type="button" class="btn btn-success btn-sm font-size-normal" ng-click="reporteComentarios()">Reporte de comentarios</button></div><div class="col-sm-9 text-right text-blue"><button type="button" class="btn btn-default btn-sm text-blue font-size-normal" ng-click="vistaPrevia()"><b><h5><i class="fa fa-file-pdf-o text-red"></i> Vista previa</h5></b></button> <button type="button" class="btn btn-default btn-sm text-black" ui-sref="^.list"><b><h5><i class="fa fa-calendar" aria-hidden="true"></i> Regresar</h5></b></button> <button type="button" class="btn btn-default btn-sm text-black" ng-click="refrescar()"><b><h5><i class="fa fa-refresh" aria-hidden="true"></i> Refrescar</h5></b></button></div></div><br><div class="row"><div class="col-sm-12 col-md-12 col-lg-12"><div ui-grid="gridOptionsDetalle" class="grid" ui-grid-selection="" ui-grid-tree-view="" ui-grid-auto-resize="" ui-grid-pagination="" ng-style="getTableHeight()"></div></div></div><div class="box-footer text-right"></div></div></form></div>');
}]);
})();
