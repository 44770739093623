(function(module) {
try {
  module = angular.module('templates-app');
} catch (e) {
  module = angular.module('templates-app', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/agenda/agenda.adjuntos.tpl.html',
    '<div class="panel panel-success"><div class="panel-heading"><h4><b>Lista de archivos adjuntos</b></h4></div><div class="panel-body"><div class="row"><div class="col-sm-12 col-md-12 col-lg-12"><div class="grid" ui-grid="gridOptionsAdjuntos" ui-grid-pagination="" ui-grid-selection="" ui-grid-auto-resize="" ng-style="getTableHeight()"></div></div></div></div><div class="box-footer text-right"><button type="button" class="btn btn-primary btn-sm" ng-click="cerrarAdjuntos()">Regresar</button></div></div>');
}]);
})();
