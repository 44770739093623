(function(module) {
try {
  module = angular.module('templates-app');
} catch (e) {
  module = angular.module('templates-app', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/login/login.tpl.html',
    '<style>\n' +
    '	html, .slide {\n' +
    '		background-color: #3c8dbc;\n' +
    '		color: #17384C;\n' +
    '	}\n' +
    '</style><form name="form" class="login-form box-shadow animated" ng-class="{\'shake\': form.$submitted && response.err}" ng-submit="submitForm(form.$valid)" novalidate=""><div class="login-wrap"><p class="login-img"><img alt="logo" src="img/logo2.png"></p><h2 class="text-center"></h2><div class="input-group"><span class="input-group-addon"><i class="fa fa-user"></i></span> <input type="text" class="form-control" name="user" ng-model="loginData.usuario" placeholder="Usuario" autofocus="" required=""></div><div class="input-group"><span class="input-group-addon"><i class="fa fa-key"></i></span> <input type="password" class="form-control" name="password" ng-model="loginData.password" placeholder="Password" required=""></div><div class="text-center" ng-show="response.message"><p class="error">{{response.message}}</p></div><button class="btn btn-primary btn-block" type="submit">Ingresar</button></div></form>');
}]);
})();
