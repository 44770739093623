(function(module) {
try {
  module = angular.module('templates-app');
} catch (e) {
  module = angular.module('templates-app', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/agenda/agenda.usuario.add.tpl.html',
    '<div class="panel"><div class="box box-primary"><div class="box-header with-border"><h1 class="box-title"><b>Agregar comentario:</b></h1></div><div class="box-body"><div class="row"><div class="col-sm-12 col-md-12 col-lg-9"><textarea class="form-control" rows="5" name="comentario" ng-model="sesion.comentario">\n' +
    '        </textarea></div></div></div><div class="box-footer text-right"><button type="button" class="btn btn-success btn-sm" ng-click="actualizarComentario()" ng-show="!existComment">Actualizar</button> <button type="button" class="btn btn-success btn-sm" ng-click="guardarComentario()" ng-show="existComment">Guardar</button></div></div></div>');
}]);
})();
