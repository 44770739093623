(function(module) {
try {
  module = angular.module('templates-app');
} catch (e) {
  module = angular.module('templates-app', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/agenda/agenda.editaDetalle.tpl.html',
    '<div class="panel"><div class="box box-primary"><div class="box-header with-border"><div class="row"><div class="col-sm-3"><h1 class="box-title"><b>Edición detalle de agenda</b></h1></div><div class="col-sm-6 text-right"></div><div class="col-sm-3 text-right"><button type="button" class="btn btn-success btn-sm" ng-click="guardarCambiosDetalle()">Guardar</button> <button type="button" class="btn btn-primary btn-sm" ng-click="cerrarComentario()">Regresar</button></div></div></div><div class="box-body"><div class="panel panel-success"><div class="panel-heading"><h5><b>No. {{tmpDetalle.numeracion}} agenda {{sesion.correlativo}}-{{sesion.anio}}</b></h5></div><div class="panel-body"><div class="row"><div class="col-sm-12 col-md-12 col-lg-12"><textarea class="form-control font-size-normal" rows="10" name="descripcion" ng-model="tmpDetalle.descripcion">\n' +
    '            </textarea></div></div></div></div></div></div></div>');
}]);
})();
