(function(module) {
try {
  module = angular.module('templates-app');
} catch (e) {
  module = angular.module('templates-app', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/agenda/agenda.addadjuntos.tpl.html',
    '<div class="box box-primary"><div class="box-header with-border"><h3 class="box-title">Lista de archivos adjuntos</h3></div><form name="form" ng-submit="addFile(form.$valid)" novalidate=""><div class="box-body"><div class="panel panel-success"><div class="panel-heading"><b>{{detalle.descripcion}} agenda {{sesion.correlativo}}-{{sesion.anio}}</b></div><div class="panel-body"><div class="row"><div class="col-sm-6" ng-class="{ \'has-error\' : form.descripcion.$invalid && form.$submitted }"><label class="control-label" for="descripcion">Descripción</label> <input class="form-control" name="descripcion" ng-model="archivo.descripcion" required=""></div><div class="col-sm-2 col-md-2 col-lg-2" ng-class="{ \'has-error\' : form.folios.$invalid && form.$submitted }"><label class="control-label" for="folios">Folios</label> <input class="form-control" type="number" name="folios" ng-model="archivo.folios" required=""></div><div class="col-sm-4"><br><label class="btn btn-primary btn-sm">Elegir Archivo <input type="file" file-model="myFile2" style="display: none;" accept=".pdf,.doc,.docx"></label> <button type="submit" class="btn btn-success btn-sm">Agregar</button><br><b><span ng-bind="myFile2.name"></span></b></div></div><br><div class="row"><div class="col-sm-12"><div ui-grid="gridOptionsAdjuntos" ui-grid-pagination="" ui-grid-selection="" class="grid" ng-hide="adjuntosHide" ui-grid-auto-resize=""></div></div></div></div></div></div><div class="box-footer text-right"><button type="button" class="btn btn-primary btn-sm" ng-click="cerrarAdjuntos()">Regresar</button></div></form></div>');
}]);
})();
