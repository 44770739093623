(function(module) {
try {
  module = angular.module('templates-app');
} catch (e) {
  module = angular.module('templates-app', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/agenda/agenda.editadjuntos.tpl.html',
    '<div class="box box-primary"><div class="box-header with-border"><h3 class="box-title">Actualizar sesión de Pleno</h3></div><form name="form" ng-submit="submitForm(form.$valid)" novalidate=""><div class="box-body"></div><div class="row"><div class="box-header with-border"><div class="col-sm-6"><h5 class="box-title">Archivos adjuntos:</h5></div></div></div><div class="row"><div class="col-sm-6"><label class="control-label" for="descripcion">Descripción</label> <input class="form-control" name="descripcion" ng-model="archivo.descripcion" required=""></div></div><div class="row"><div class="col-sm-6"><label class="btn btn-primary btn-sm">Elegir Archivo <input type="file" file-model="myFile2" style="display: none;"></label> <button type="button" class="btn btn-success btn-sm" ng-click="addFile()">Agregar</button><br><b><span ng-bind="myFile2.name"></span></b></div></div><div class="row"><div class="col-sm-9"><div ui-grid="gridOptionsAdjuntos" ui-grid-pagination="" ui-grid-selection="" class="grid" ng-hide="adjuntosHide" ui-grid-auto-resize=""></div></div></div><div class="box-footer text-right"><button type="submit" class="btn btn-warning btn-sm">Actualizar</button> <button type="button" class="btn btn-danger btn-sm" ng-click="deleteSesion()">Eliminar</button> <button type="button" class="btn btn-primary btn-sm" ui-sref="^.listadmin">Cancelar</button></div></form></div>');
}]);
})();
