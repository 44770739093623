(function(module) {
try {
  module = angular.module('templates-app');
} catch (e) {
  module = angular.module('templates-app', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/agenda/agenda.viewdetalle.tpl.html',
    '<style>\n' +
    '*{box-sizing: border-box;}\n' +
    '.wrapper {margin: 0 auto; width: 960px; }\n' +
    '.pdf-controls { width: 100%; display: block; background: #eee; padding: 1em;}\n' +
    '.rotate0 {-webkit-transform: rotate(0deg); transform: rotate(0deg); }\n' +
    '.rotate90 {-webkit-transform: rotate(90deg); transform: rotate(90deg); }\n' +
    '.rotate180 {-webkit-transform: rotate(180deg); transform: rotate(180deg); }\n' +
    '.rotate270 {-webkit-transform: rotate(270deg); transform: rotate(270deg); }\n' +
    '.fixed { position: fixed; top: 0; left: calc(50% - 480px); z-index: 100; width: 100%; padding: 1em; background: rgba(238, 238, 238,.9); width: 960px; }\n' +
    '</style><div class="box box-primary"><div class="box-header with-border"></div><form name="form" ng-submit="submitForm(form.$valid)" novalidate=""><div class="box-body"><div class="row" style="overflow: auto; -webkit-overflow-scrolling: touch;"><div class="col-sm-12 col-md-12 col-lg-12" style="overflow: auto; -webkit-overflow-scrolling: touch;"><iframe ng-src="{{trustSrc(iframe.src)}}" width="100%" height="600px" scrolling="yes"></iframe></div></div></div><div class="box-footer text-right"></div></form></div>');
}]);
})();
