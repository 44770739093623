(function(module) {
try {
  module = angular.module('templates-app');
} catch (e) {
  module = angular.module('templates-app', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/usuario/usuario.list.tpl.html',
    '<div class="box box-primary"><div class="box-header with-border"><h1 class="box-title"><b>Listado de usuarios</b></h1></div><div class="box-body"><button type="submit" class="btn btn-success btn-md" ui-sref="^.add">Nuevo</button><div ui-grid="gridOptionsUsuario" ui-grid-pagination="" ui-grid-selection="" ui-grid-edit="" class="grid" ui-grid-auto-resize=""></div></div></div>');
}]);
})();
