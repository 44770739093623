(function(module) {
try {
  module = angular.module('templates-app');
} catch (e) {
  module = angular.module('templates-app', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/agenda/agenda.votacion.tpl.html',
    '<div class="panel"><div class="box box-primary"><div class="box-header with-border"><div class="row"><div class="col-sm-2"><h1 class="box-title"><b>Votación</b></h1></div><div class="col-sm-4"><h4 class="box-title"><b>Agenda {{sesion.correlativo}}-{{sesion.anio}}</b></h4></div></div></div><form name="form" ng-submit="submitForm(form.$valid)" novalidate=""><div class="box-body"><div class="col-sm-12 text-right"><button type="submit" class="btn btn-success btn-sm" ng-show="voto.votoId==null">Guardar</button> <button type="submit" class="btn btn-success btn-sm" ng-show="voto.votoId!=null">Actualizar</button> <button type="button" class="btn btn-primary btn-sm" ng-click="cerrarVotacion()">Regresar</button></div><div class="row"><div class="col-sm-12" ng-class="{ \'has-error\' : form.tipoVoto.$invalid && form.$submitted }"><label class="control-label" for="estadoId">Tipo Voto:</label><ui-select name="tipoVoto" ng-model="voto.tcTipoVoto.tipoVotoId" theme="bootstrap" sortable="true" required=""><ui-select-match class="ui-select-match" placeholder="Seleccione...">{{$select.selected.descripcion}}</ui-select-match><ui-select-choices class="ui-select-choices" repeat="item.tipoVotoId as item in tiposVoto | filter: {descripcion: $select.search}"><div ng-bind-html="item.descripcion | highlight: $select.search"></div></ui-select-choices></ui-select></div></div><div class="row"><div class="col-sm-12"><div class="panel panel-success"><div class="panel-heading"><h5><b>Observaciones voto</b></h5></div><div class="panel-body"><div class="row"><textarea class="form-control font-size-normal" rows="10" name="observaciones" ng-model="voto.observaciones">\n' +
    '\n' +
    '                </textarea></div></div></div></div></div></div></form></div></div>');
}]);
})();
