(function(module) {
try {
  module = angular.module('templates-app');
} catch (e) {
  module = angular.module('templates-app', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/agenda/agenda.list.tpl.html',
    '<div class="box box-primary"><div class="box-body"><h2 class="text-center">AGENDA DE SESIONES DEL PLENO</h2><div class="col-sm-12"><h2 class="text-center">{{ calendarTitle | uppercase }}</h2><div class="btn-group"><label class="btn btn-default text-blue" ng-model="calendarView" ng-click="controla(1)" uib-btn-radio="\'month\'">Mes</label> <label class="btn btn-default text-blue" ng-model="calendarView" ng-click="controla(2)" uib-btn-radio="\'week\'">Semana</label> <label class="btn btn-default text-blue" ng-model="calendarView" ng-click="controla(3)" uib-btn-radio="\'day\'">Día</label></div><button class="btn btn-info" mwl-date-modifier="" date="calendarDay" ng-click="cambiarFecha(calendarDay)" decrement="calendarView"><</button> <button class="btn btn-default text-blue" mwl-date-modifier="" date="calendarDay" ng-click="cambiarFecha(calendarDay)" set-to-today="">Hoy</button> <button class="btn btn-info" mwl-date-modifier="" date="calendarDay" ng-click="cambiarFecha(calendarDay)" increment="calendarView">></button><mwl-calendar view="calendarView" current-day="calendarDay" events="events" view-title="calendarTitle" on-event-click="busqueda(calendarEvent)" on-timespan-click="agregarSesion(calendarDate)" on-event-times-changed="calendarEvent.startsAt = calendarNewEventStart; calendarEvent.endsAt = calendarNewEventEnd" on-edit-event-click="eventEdited(calendarEvent)" on-delete-event-click="eventDeleted(calendarEvent)" edit-event-html="\'<i class=\\\'glyphicon glyphicon-pencil\\\'></i>\'" delete-event-html="\'<i class=\\\'glyphicon glyphicon-remove\\\'></i>\'" cell-is-open="false" view-date="calendarDay" auto-open="false" cell-modifier="cellModifier(calendarCell)" cell-auto-open-disabled="true"></mwl-calendar></div></div></div>');
}]);
})();
